import React from 'react'
import Helmet from 'react-helmet'

const HtmlHead = () => (
  <Helmet>
    <title>Saber Academy</title>
    <meta name="description" content="Šerm se světelným mečem - pravidelné kurzy, team building, narozeninové oslavy." />
    <meta name="keywords" content="Star Wars,Lightsaber,Světelný meč,Šerm,Sport,kurz,Kurzy,Jedi,Jedi training,Sith,Form,Bojový sport,Bojové umění,Jedi Academy" />
  </Helmet>
)

export default HtmlHead
