import React from 'react'
import styled from '@xstyled/styled-components'

const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
`

const Container = styled.div`
  text-align: center;
  & > img {
    height: 400px;
  }
`

const Error500Page = ({ error }) => (
  <Wrapper>
    <Container>
      <h1>An error occurred</h1>
      <p>¯\_(ツ)_/¯</p>
      {error && <p>{error.message}</p>}
    </Container>
  </Wrapper>
)

export default Error500Page
