import React from 'react'
import { Box } from '@xstyled/styled-components'

const Content = ({ children }) => (
    <Box display="flex" flexDirection="column" minHeight="300px">
        {children}
    </Box>
)

export default Content
