import React from 'react'
import Helmet from 'react-helmet'
import { ThemeProvider } from '@xstyled/styled-components'

const Fonts = ({ fontLinks }) => {
    if (!fontLinks || fontLinks.length < 1) return null
    return (
        <Helmet>
            {fontLinks.map(fontLink => (
                <link key={fontLink} href={fontLink} rel="stylesheet"></link>
            ))}
        </Helmet>
    )
}

const CustomThemeProvider = ({ theme, children }) => {
    return (
        <>
            <Fonts fontLinks={theme.fontLinks} />
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </>
    )
}

export default CustomThemeProvider
