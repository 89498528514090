import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import { useHistory } from 'react-router-dom';

const PromoSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2 2 0 2;
    ${breakpoints({
        lg: css`
            margin: 3 6 0 6;
        `,
    })}
`

const Card = styled.div`
    display: flex;
    border: 1px solid;
    border-color: backgroundDarker;
    margin-bottom: 0;
    flex-direction: column;
    text-align: center;
    & > :first-child {
        height: 40vh;
    }
    & > :nth-child(2) {
        background-color: backgroundLighter;
        padding: 3;
        p {
            font-size: 3;
            color: grayLight;
            text-align: left;
            margin-down: 3;
        }
        b {
            color: white;
        }
        p.price {
            span {
                color: white;
            }
            margin-bottom: 0;
            color: white;
        }
    }
    ${breakpoints({
        lg: css`
            margin-bottom: 0;
            flex-direction: ${props => props.even ? 'row-reverse' : 'row'};
            & > :first-child {
                width: 30%;
                height: 200px;
                margin: 3;
            }
            & > :nth-child(2) {
                width: 70%;
                padding: 4;
                p {
                    text-align: center;
                }
                p.price {
                    span {
                        font-size: 4;
                        margin-left: 1em;
                    }
                }
            }
        `,
    })}
`

const VideoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  & > iframe {
      object-fit: cover;
      flex-grow: 0;
      width: 100%;
      height: 100%;
  }
`

const Button = styled.button`
    display: inline-block;
    background-color: buttonPrimaryBackground;
    font-size: 3;
    padding: 2;
    margin: 1;
    border: 1px solid;
    border-color: formInputBorder;
    text-decoration: none;
    color: white;
    border-radius: 3;
`

const PromoSection = () => {
    const history = useHistory();

    const handleButtonClick = (event) => {
        // Prevent default behavior to handle navigation programmatically
        event.preventDefault();

        // Navigate to the specified URL
        history.push('/Onlinelekce');
    }
    return (
        <PromoSectionWrapper>
                <Card>
                    <VideoContainer>
                    <iframe
                        src="https://www.youtube.com/embed/6mpOnJG_xdc?si=4Zn-nWKyJ18YfVjh"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                        allowfullscreen="allowfullscreen"
                    ></iframe>
                    </VideoContainer>
                    <div>
                        <p><b>Online lekce:</b> Kurz Saber Skillů se světelným mečem. 
                        Vhodný pro začátečníky, děti i dospělé. Trénujte s 
                        námi kdykoli a odkudkoli.</p>
                        <Button as="a" href="/Onlinelekce" onClick={handleButtonClick}> E-shop</Button>
                    </div>
                </Card>
        </PromoSectionWrapper>
    )
}

export default PromoSection