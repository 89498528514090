import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled, { css } from "@xstyled/styled-components";
import { breakpoints } from "@xstyled/system";
import coverImage6 from "./cover6.jpg";
import coverImage5 from "./cover5.jpg";

const HeadingSection = styled.div`
  width: 100%;
  position: relative;

  & img {
    display: block;
    min-height: 16rem;
    max-height: 69vh;
    object-fit: cover; /* or object-fit: contain; */
  }
`;

const Heading = styled.h1`
  margin-top: 3;
  color: white;
  text-shadow: heading;
  position: absolute;
  top: 1rem;
  left: 2rem;
  z-index: 10;
  ${breakpoints({
    md: css`
      top: 3rem;
      left: 6rem;
    `,
  })}
`;

const StyledCarousel = styled(Carousel)`
  img {
    object-fit: cover;
    object-position: center top;
  }
`;

const Hero = () => {
  return (
    <HeadingSection>
      <Heading>Saber Academy</Heading>
      <StyledCarousel
        infiniteLoop
        interval={5000}
        autoPlay
        showThumbs={false}
        showStatus={false}
        centerMode={false}
        swipeable
      >
        <img src={coverImage5} alt="" />
        <img src={coverImage6} alt="" />
      </StyledCarousel>
    </HeadingSection>
  );
};

export default Hero;
