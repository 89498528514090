import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Normalize, { NormalizeBaseFonts } from './components/Normalize'
import HtmlHead from './components/HtmlHead'
import SeoHack from './components/SeoHack'
import Layout from './components/Layout'
import Theme from './theme'
import defaultTheme from './theme/themes/default'
import Home from './pages/Home'
import Error404 from './pages/Error404'
import Error500 from './pages/Error500'
import Onlinelekce from './pages/Onlinelekce'


class App extends React.Component {

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error }
  }
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidCatch(error, errorInfo) {
    console.log('App did catch', error, errorInfo)
    // Sentry.captureException(error)
    this.setState({ error })
  }

  render() {
    if (this.state.error) {
      return (
          <Error500 error={this.state.error} />
      )
    }
    return (
      <Theme theme={defaultTheme}>
        <Router>
          <Layout>
            <Normalize />
            <NormalizeBaseFonts />
            <HtmlHead />
            <SeoHack />
            {/*<ScrollToTop />*/}
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/Onlinelekce" exact component={Onlinelekce} />
              <Route component={Error404} />
            </Switch>
          </Layout>
        </Router>
      </Theme>
    )
  }
}

export default App