import React from "react";
import styled, { css } from "@xstyled/styled-components";
import { breakpoints, th } from "@xstyled/system";

const Wrapper = styled.div`
  padding: 3 3 0 3;
  padding-bottom: ${(props) => props.last && th.space(3)};
  width: 100%;
  background-color: backgroundLightest;
  display: flex;
  flex-direction: column;
  & > div {
    background: black;
    text-align: center;
    width: 100%;
    margin-bottom: 3;
    &:last-child {
      margin-bottom: 0;
    }
    display: flex;
    flex-direction: column;
  }
  & > div > div {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
  ${breakpoints({
    lg: css`
      flex-direction: row;
      & > div {
        width: 33%;
        margin-right: 3;
        margin-bottom: 0;
      }
      & > :last-child {
        margin-right: 0;
      }
    `,
  })}
`;

const VideoSection = () => {
  return (
    <>
      <Wrapper>
        <div>
          <h4>Choreografie</h4>
          <div>
            <iframe
              src="https://www.youtube.com/embed/sMTYSz04u44"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowfullscreen="allowfullscreen"
            ></iframe>
          </div>
        </div>
        <div>
          <h4>Kurzy</h4>
          <div>
            <iframe
              src="https://www.youtube.com/embed/5yNItJ8oAJA"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowfullscreen="allowfullscreen"
            ></iframe>
          </div>
        </div>
        <div>
          <h4>Oslavy</h4>
          <div>
            <iframe
              src="https://www.youtube.com/embed/K2oaVL5U98E"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowfullscreen="allowfullscreen"
            ></iframe>
          </div>
        </div>
      </Wrapper>
      <Wrapper last>
        <div>
          <h4>Fan Film</h4>
          <div>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/rUrHzsjwNH4?si"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
            ></iframe>
          </div>
        </div>
        <div>
          <h4>Kurzy</h4>
          <div>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/yricoPdUeU4"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
            ></iframe>
          </div>
        </div>
        <div>
          <h4>Turnaje</h4>
          <div>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/KkHLj7Ii0js"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
            ></iframe>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default VideoSection;
