import styled, { useUp } from '@xstyled/styled-components'
import React from 'react'
import { IoIosMail, IoMdCall } from 'react-icons/io'


const ContactContainer = styled.div`
  font-size: 2;
  color: white;
  margin-left: auto;
  display: flex;
  align-items: center;
  & > a {
    color: white;
    text-decoration: underline;
    font-weight: bold;
    margin-left: 1rem;
  }
`

const Contact = () => {
    const upMd = useUp('md')
    return (
        <ContactContainer>
            {upMd ? (   
                <>   
                    Tel:&nbsp;<a href="tel:+420723452070">723 452 070</a>
                </>
            ) : (
                <a href="tel:+420723452070">
                    <IoMdCall size="1.5em" />
                </a>
            )}
            <a href="mailto:info@saberacademy.cz">
                <IoIosMail size="1.5em" />
            </a>
        </ContactContainer>
    )
}

export default Contact
