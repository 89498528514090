import React from 'react'
import { nanoid } from 'nanoid'
import styled from '@xstyled/styled-components'

const StyledTable = styled.table`
  font-size: 2;
  width: 100%;
  background-color: backgroundLightest;
  td {
    padding: 2;
    background-color: backgroundDarker;
  }
  td:first-child {
    width: 45%;
  }
  td:nth-child(2) {
    width: 25%;
  }
`

const CoursePriceTable = ({ rows }) => {
  return (
    <StyledTable>
      <tbody>
      {rows && rows.map(row => (
        <tr key={nanoid()}>
          {row && row.map(item => <td key={nanoid()}>{item}</td>)}
        </tr>
      ))}
      </tbody>
    </StyledTable>
  )
}

export default CoursePriceTable
