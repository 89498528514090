import React from 'react'
import { Formik, Field } from 'formik'
import emailjs from 'emailjs-com'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'

const Wrapper = styled.div`
  background-color: backgroundLighter;
  border-color: backgroundDarker;
  ${breakpoints({
        lg: css`
            margin: 4;
        `,
    })}
`

const StyledH3 = styled.h3`
  text-align: center;
`

const StyledForm = styled.form`
  padding: 3;
  display: flex;
  & > div {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  input {
    padding: 2;
    margin: 1;
    border: 1px solid;
    border-color: formInputBorder;
    border-radius: 3;
  }
  button {
    background-color: buttonPrimaryBackground;
    font-size: 3;
    padding: 2;
    margin: 1;
    border: 1px solid;
    border-color: formInputBorder;
    color: white;
    border-radius: 3;
  }
  textarea {
    flex-grow: 1;
    padding: 2;
    margin: 1;
    border-radius: 3;
  }
  p.honeypot {
    display: none;
  }
`

const initialValues = {
  name: '',
  email: '',
  phone: '',
  message: '',
}

const formSend = (values, formikBag) => {
  emailjs.init('user_7RfKpfs2L45OCSqllUgbe')
  emailjs.send('service_gtcmezn','template_4gwhwwa', values)
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text)
      alert('Odesláno, děkuji :)')
    }, (err) => {
      console.log('FAILED...', err)
      alert('Omlouvám se, došlo k chybě.')
    })
}

const ContactForm = () => {
  return (
    <Wrapper>
        <StyledH3>Kontaktujte mne</StyledH3>
        <Formik onSubmit={formSend} initialValues={initialValues}>
          {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
            <StyledForm onSubmit={handleSubmit}>
                <input type="hidden" name="form-name" value="contact" />
                <p className="honeypot">
                  <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                </p>
                <div>
                    <Field type="text" name="name" placeholder="Jméno" required />
                    <Field type="email" name="email" placeholder="E-mail" required />
                    <Field type="phone" name="phone" placeholder="Telefon (volitelně)" />
                    <button type="submit">Odeslat</button>
                </div>
                <div>
                    <Field component="textarea" name="message" placeholder="Vaše zpráva (volitelně)" />
                </div>
            </StyledForm>
          )}
        </Formik>
    </Wrapper>
  )
}

export default ContactForm
