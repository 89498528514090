import React from 'react'
import styled from '@xstyled/styled-components'
import { IoMdExpand } from 'react-icons/io'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  & > img {
      object-fit: cover;
      flex-grow: 0;
      width: 100%;
      height: 100%;
  }
`

const StyledExpandIcon = styled(IoMdExpand)`
  position: absolute;
  left: 10px;
  top: 10px;
`

const Image = ({ src, onClick }) => (
  <StyledContainer>
    <StyledExpandIcon />
    <img src={src} alt="" onClick={onClick} />
  </StyledContainer>
)

export default class ImageGalleryModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { image, images } = this.props
    const { photoIndex, isOpen } = this.state;
    const onClick = () => this.setState({ isOpen: true })

    return (
      <>
        <Image src={image} onClick={onClick} />

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </>
    );
  }
}

