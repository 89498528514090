import React from 'react'
import styled from 'styled-components'

const firstKeywords = ['Star Wars', 'Lightsaber', 'Světelný meč', 'Šerm', 'Sport', 'kurz', 'Kurzy', 'Jedi', 'Jedi training', 'Sith', 'Form', 'Bojový sport', 'Bojové umění', 'Jedi Academy']
const secondKeywords = ['Příměstský tábor', 'Narozeninová párty', 'Birthday party program', 'Zážitek', 'Teambuilding', 'Event', 'Jedi Temple', 'Padawan', 'Master', 'Jedi Knight', 'Single saber', 'Saber staff', 'Saber', 'Ataru', 'Soresu', 'Makashi']

const Times = ({ children, times }) => {
    const ret = []
    for(var i = 0; i < times; i++) {
        ret.push(children)
    }
    return ret
}

const Wrapper = styled.div`
    font-size: 0
`

const SeoHack = () => (
    <Wrapper>
        <Times times={20}>{firstKeywords.map(keyword => <div key={keyword}>{keyword}</div>)}</Times>
        <Times times={10}>{secondKeywords.map(keyword => <div key={keyword}>{keyword}</div>)}</Times>
    </Wrapper>
)

export default SeoHack