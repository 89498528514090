import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'

const FeaturesSectionWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 5%, #ffffff 85%, rgba(255, 255, 255, 0) 100%);
    background-color: backgroundDarker;
    border-top: 2px solid;
    border-color: backgroundLighter;
    ${breakpoints({
        lg: css`
            padding: 0 6;
            mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 25%, #ffffff 75%, rgba(255, 255, 255, 0) 100%);
            flex-direction: row;
        `,
    })}
`

const Item = styled.div`
    flex-grow: 1;
    margin: 1;
    padding: 1 3;
    text-align: center;
    display: flex;
    flex-direction: row;
    & > img {
        align-self: center;
        margin-right: 1em;
    }
    & h3 {
        width: 100%;
    }
`

const Badge = styled.img`
    height: 90px;
    display: none;
    ${breakpoints({
        lg: css`
            display: block;
        `,
    })}
`

const FeaturesSection = ({ features, leftBadge, rightBadge }) => {
    if (!features || features.length < 1) return null
    return (
        <>
            <FeaturesSectionWrapper>
                <Badge src={leftBadge} alt="" />
                {features.map(feature => (
                    <Item key={`${feature.title}-${feature.icon}`}>
                        <h3 key={feature.title}>{feature.title}</h3>
                    </Item>
                ))}
                <Badge src={rightBadge} alt="" />
            </FeaturesSectionWrapper>
        </>
    )
}

export default FeaturesSection
