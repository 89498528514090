import React from 'react'
import Helmet from 'react-helmet'
import { breakpoints } from '@xstyled/system'
import { createGlobalStyle, css } from '@xstyled/styled-components'

const GlobalStyle = createGlobalStyle`
  /* create consistent base for relative units */
  html {
    font-size: 14px;
    ${breakpoints({
      sm: css`
        font-size: 10px;
      `,
      md: css`
        font-size: 12px;
      `,
      lg: css`
        font-size: 14px;
      `,
    })}

    h1 {
      font-size: 9;
      margin: 3 0;
    }
    h2 {
      font-size: 7;
      margin: 3 0;
    }
    h3 {
      font-size: 6;
      margin: 3 0;
    }
    h4 {
      font-size: 5;
      margin: 3 0;
    }
    p {
      font-size: 4;
    }
  }
  body {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
      Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }
`

const Fonts = () => {
  return (
    <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Raleway%3A400%2C600&display=swap" rel="stylesheet"></link>
    </Helmet>
  )
}

const Normalize = () => (
  <>
    <Fonts />
    <GlobalStyle />
  </>
)

export default Normalize
