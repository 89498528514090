import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import sabers from './sabers.png'

const Wrapper = styled.div`
  padding: 3;
  background-color: backgroundLighter;
  display: flex;
  font-size: 3;
  width: 100%;
  height: 80px;
  justify-content: center;
  align-items: center;
  & a {
    color: white;
  }
  & img {
    background: white;
    width: 130px;
    margin-left: 2;
    ${breakpoints({
        lg: css`
           margin-left: 3;
        `,
    })}
  }
`

const SabersPromo = () => {
  return (
    <Wrapper>
        <span>
          Trénujeme s meči od&nbsp;<a href="https://stuntsabers.eu/">Stunt&nbsp;Sabers</a>
        </span>
        <img src={sabers} alt="" />
    </Wrapper>
  )
}

export default SabersPromo
