import React from 'react'
import styled from '@xstyled/styled-components'
import image404 from './404.png'

const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
`

const Container = styled.div`
  text-align: center;
  & > img {
    height: 400px;
  }
`

const Error404Page = () => (
  <Wrapper>
    <Container>
      <h1>Page not found</h1>
      <img alt="Not found" src={image404} />
    </Container>
  </Wrapper>
)

export default Error404Page
