import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import * as Sentry from '@sentry/browser'
import { Integrations } from "@sentry/tracing"
import App from './App'
import * as serviceWorker from './serviceWorker'

Sentry.init({
  dsn: "https://aecfcfbb0aeb4148ad6801881bc02cb2@o553063.ingest.sentry.io/5679789",
  integrations: [new Integrations.BrowserTracing()],
})

let history = createBrowserHistory()

ReactDOM.render(
    <Router history={history}>
        <App />
    </Router>
, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
