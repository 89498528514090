import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'

const OnlineclassWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2 2 0 2;
    ${breakpoints({
        lg: css`
            margin: 3 6 0 6;
        `,
    })}
`

const Card = styled.div`
    display: flex;
    border: 1px solid;
    border-color: backgroundDarker;
    margin-bottom: 0;
    flex-direction: column;
    text-align: center;
    & > :first-child {
        height: 40vh;
    }
    & > :nth-child(2) {
        background-color: backgroundLighter;
        padding: 3;
        p {
            font-size: 1;
            color: grayLight;
            text-align: left;
            margin-down: 3;
        }
        b {
            color: white;
        }
        p.price {
            span {
                color: white;
            }
            margin-bottom: 0;
            color: white;
        }
    }
    ${breakpoints({
        lg: css`
            margin-bottom: 0;
            flex-direction: ${props => props.even ? 'row-reverse' : 'row'};
            & > :first-child {
                width: 30%;
                height: 200px;
                margin: 3;
            }
            & > :nth-child(2) {
                width: 70%;
                padding: 4;
                p {
                    text-align: center;
                }
                p.price {
                    span {
                        font-size: 4;
                        margin-left: 1em;
                    }
                }
            }
        `,
    })}
`

const VideoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  & > iframe {
      object-fit: cover;
      flex-grow: 0;
      width: 100%;
      height: 100%;
  }
`

const Onlineclass1 = () => {
    return (
        <OnlineclassWrapper>
                <Card>
                    <VideoContainer>
                    <iframe
                        src="https://www.youtube.com/embed/6mpOnJG_xdc?si=4Zn-nWKyJ18YfVjh"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                        allowfullscreen="allowfullscreen"
                    ></iframe>
                    </VideoContainer>
                    <div>
                        <h4><b>Online kurz triků se světelným mečem: Level 1</b></h4>
                        <p>Vítejte na online kurzu Saber Skillů od Saber Academy. Saber skill je trik se světelným mečem, 
                            který je užíván ke zkrášlení choreografií, oslnění publika při akcích, pro oslavování vítězství 
                            a nebo pro provokování soupeře v souboji. V následujících lekcích si společně projdeme základní 
                            pohyby pro Saber skilly, ukážeme si, jak spolu souvisejí a naučíme se je spojovat do takzvaných 
                            Saber Skill Comb.</p>

                        <p> Kurz Vás postupně naučí ovládat světelný meč tak, abyste se od nejjednodušších otoček propracovali 
                            až ke kombinacím a trikům, které zanechají Vaše publikum v němém úžasu.</p>

                        <p> Kurzem Vás provede profesionální trenér šermu světelným mečem.</p>
 
                        <p> Triky můžete cvičit i bez světelného meče například s plastovou či dřevěnou tyčí.</p>
                    </div>
                </Card>
        </OnlineclassWrapper>
    )
}

export default Onlineclass1