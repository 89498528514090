import React from 'react'
import { createGlobalStyle } from '@xstyled/styled-components'
import Header from '../Header'
import Content from './components/Content'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
  }
`

const CommonLayout = ({ children }) => {
    return (
        <>
            <GlobalStyle />
            <Header />
            <Content>{children}</Content>
            {/*<Footer />*/}
        </>
    )
}

export default CommonLayout
