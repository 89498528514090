import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import ImageGalleryModal from '../ImageGalleryModal'

const ZigZagSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2 2;
    ${breakpoints({
        lg: css`
            margin: 3 6;
        `,
    })}
`

const ZigZagCard = styled.div`
    display: flex;
    border: 1px solid;
    border-color: backgroundDarker;
    margin-bottom: 2;
    flex-direction: column;
    text-align: center;
    & > :first-child {
        height: 40vh;
    }
    & > :nth-child(2) {
        background-color: backgroundLighter;
        padding: 3;
        p {
            font-size: 3;
            color: grayLight;
            text-align: left;
        }
        b {
            color: white;
        }
        p.price {
            span {
                color: white;
            }
            margin-bottom: 0;
            color: white;
        }
    }
    ${breakpoints({
        lg: css`
            margin-bottom: 3;
            flex-direction: ${props => props.even ? 'row-reverse' : 'row'};
            & > :first-child {
                width: 30%;
                height: 350px;
                margin: 3;
            }
            & > :nth-child(2) {
                width: 70%;
                padding: 4;
                p {
                    text-align: center;
                }
                p.price {
                    span {
                        font-size: 4;
                        margin-left: 1em;
                    }
                }
            }
        `,
    })}
`

const IconSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
    & > :nth-child(2) {
        margin-left: 2;
    }
    ${breakpoints({
        lg: css`
            & > :nth-child(2) {
                margin-left: 4;
            }
        `,
    })}
`

const ZigZagSection = ({ items }) => {
    if (!items || items.length < 1) return null
    return (
        <ZigZagSectionWrapper>
            {items.map((item, i) => (
                <ZigZagCard key={i} even={i % 2 === 0}>
                    <ImageGalleryModal image={item.image} images={item.images} />
                    <div>
                        <IconSection>
                            <img src={item.icon} alt={item.title} width="60"/>
                            <h3>{item.title}</h3>
                        </IconSection>
                        {item.subtitle && <h4 key={item.subtitle}>{item.subtitle}</h4>}
                        {item.description}
                    </div>
                </ZigZagCard>
            ))}
        </ZigZagSectionWrapper>
    )
}

export default ZigZagSection