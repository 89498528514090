const defaultTheme = {
  breakpoints: {
    // same as xstyled default
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  colors: {
    // primary: '#3C3C3C',
    textPrimary: 'white',
    backgroundPrimary: 'black',
    backgroundLightest: '#777777',
    backgroundLighter: '#1A1617',
    backgroundDarker: '#2A2A2A',
    grayLight: '#D8E1E8',
    formInputBorder: '#777777',
    buttonPrimaryBackground: '#60BF92',
    error: 'red',
    success: 'green',
    warning: 'orange',
    
  },
  fonts: {
    primary: 'Arial',
    heading: 'Roboto, sans-serif',
  },
  fontLinks: ['https://fonts.googleapis.com/css?family=Roboto&display=swap'],
  fontSizes: ['0rem', '1rem', '1.25rem', '1.5rem', '1.8rem', '2.0rem', '2.4rem', '3rem', '4rem', '4.5rem'],
  // lineHeights?? = podle mně nepotřebujeme v DS
  fontWeights: {
    default: null,
    thin: 100,
    normal: 400,
    thick: 900,
  },
  letterSpacings: {
    default: null,
    sm: '2px',
    md: '4px',
    lg: '8px',
  },
  borders: {
    slim: '1px solid',
    medium: '3px solid',
    thick: '10px solid',
  },
  // ...........................
  radii: {
    sm: '2px',
    md: '5px',
    lg: '10px',
    xl: '20px',
  },
  shadows: {
    heading: '4px 4px 2px #2A2A2A',
    smLeftGray: '-6px 6px 10px 10px #F1F1F1',
    smLeftGray2: '-6px 6px 10px 10px #BFCCD6',
  },
  spaces: [0, 4, 8, 16, 24, 48, 96, 144, 192, 240], // same as xstyled default
  transitions: {
    simpleLong: 'all 2s',
  },
  zIndices: [0, 1, 2, 3, 30, 40, 100, 200, 300, 9999],
}

export default defaultTheme
