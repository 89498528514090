import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import { HashLink as Link } from 'react-router-hash-link'
import Contact from './Contact'
import HorizontalMenu from './HorizontalMenu'
import LogoImage from './logo.png'
import featIcon from './icon.png'

const StyledHeader = styled.div`
  text-align: center;
  padding: 2 2;
  color: white;
  background-color: backgroundLighter;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-bottom: 2px solid;
  border-color: backgroundDarker;
  ${breakpoints({
        md: css`
          padding: 2 3;
        `,
    })}
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  & > * {
    display: flex;
    text-decoration: none;
  }
`

const Logo = styled.img`
    width: 2.5rem;
    height: 2.5rem;
`

const logoLink = '/'

const menuItems = [
    {
      title: 'Domů',
      icon: featIcon,
      link: '/',
    },
    {
      title: 'Kontakt',
      icon: featIcon,
      link: '#contact',
    },
    {
      title: 'Online lekce',
      icon: featIcon,
      link: '/Onlinelekce',
    },
  ]

const Header = () => {
    return (
        <StyledHeader>
            <LogoContainer>
                <Link to={logoLink}>
                    <Logo alt="Prague Saber Logo" src={LogoImage} />
                </Link>
            </LogoContainer>
            <HorizontalMenu items={menuItems} />
            <Contact />
        </StyledHeader>
    )
}

export default Header
