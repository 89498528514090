import styled, { Box, css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'


const Menu = styled.div`
  margin-left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  font-size: 2;
  & > * {
    margin-left: 3;
    color: white;
  }
  & a {
    color: white;
    text-decoration: none;
  }
  ${breakpoints({
        md: css`
          margin-left: 5;
          & > * {
            margin: 0 3;
          }
        `,
    })}
`

const HorizontalMenu = ({ items }) => {
  return (
    <Menu>
      {items.map(item => (
        <Box key={`${item.link}-${item.title}`}>
          {item.link ? <Link to={item.link}>{item.title}</Link> : <a href={item.externalLink}>{item.title}</a>}
        </Box>
      ))}
    </Menu>
  )
}

export default HorizontalMenu
