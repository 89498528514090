import React from 'react'
import { createGlobalStyle } from '@xstyled/styled-components'

const GlobalStyle = createGlobalStyle`
  /* apply a natural box layout model to all elements, but allowing components to change */
  html {
    box-sizing: border-box;
    height: 100%;
    margin: 0;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  /* System Fonts as used by GitHub */
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
      Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }
`

// TODO add this normalization: https://polished.js.org/docs/#normalize

const Normalize = () => <GlobalStyle />

export default Normalize
