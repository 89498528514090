import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${breakpoints({
        lg: css`
              flex-direction: row;
              & > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
              & > div:nth-child(1) {
                width: 60%;
              }
              & > div:nth-child(2) {
                padding-left: 3;
                width: 40%;
              }
        `,
    })}
`

const TwoColumnSection = ({ children }) => {
  return (
    <Container>
        {React.Children.map(children, (child, i) => {
          return <div>{child}</div>
        })}
    </Container>
  )
}

export default TwoColumnSection
