import React from 'react'
import styled from '@xstyled/styled-components'
import FeaturesSection from '../../components/FeaturesSection'
import Hero from '../../components/Hero'
import Footer from '../../components/Footer'
import ZigZagSection from '../../components/ZigZagSection'
import CoursePriceTable from '../../components/CoursePriceTable'
import VideoSection from '../../components/VideoSection'
import ContactForm from '../../components/ContactForm'
import SabersPromo from '../../components/SabersPromo'
import TwoColumnSection from '../../components/TwoColumnSection'
import featIcon1 from './icon1.png'
import featIcon2 from './icon2.png'
import featIcon5 from './icon5.png'
import featIcon3 from './icon3.png'
import featIcon4 from './icon4.png'
import featIcon6 from './icon6.png'
import PromoSection from '../../components/PromoSection'

const Wrapper = styled.div`
    background-color: backgroundPrimary;
    color: textPrimary;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const features = [{
    title: 'Pravidelné kurzy',
}, {
    title: 'Příměstské tábory',
}, {
    title: 'Zážitkové akce',
}]
;

const images = {
    kurz: [
        '/images/dospeli/image01.jpg',
        '/images/dospeli/image12.jpg',
        '/images/dospeli/image13.jpg',
        '/images/dospeli/image14.jpg',
        '/images/dospeli/image15.jpg',
        '/images/dospeli/image16.jpg',
        '/images/dospeli/image02.jpg',
        '/images/dospeli/image03.jpg',
        '/images/dospeli/image04.jpg',
        '/images/dospeli/image05.jpg',
        '/images/dospeli/image06.jpg',
        '/images/dospeli/image07.jpg',
        '/images/dospeli/image08.jpg',
        '/images/dospeli/image09.jpg',
        '/images/dospeli/image10.jpg',
        '/images/dospeli/image11.jpg',
    ],
    kurzDeti: [
        '/images/deti/image01.jpg',
        '/images/deti/image02.jpg',
        '/images/deti/image03.jpg',
        '/images/deti/image11.jpg',
        '/images/deti/image12.jpg',
        '/images/deti/image13.jpg',
        '/images/deti/image04.jpg',
        '/images/deti/image05.jpg',
        '/images/deti/image06.jpg',
        '/images/deti/image07.jpg',
        '/images/deti/image08.jpg',
        '/images/deti/image09.jpg',
        '/images/deti/image10.jpg',
    ],
    party: [
        '/images/oslavy/image01.jpg',
        '/images/oslavy/image02.jpg',
        '/images/oslavy/image03.jpg',
        '/images/oslavy/image04.jpg',
        '/images/oslavy/image05.jpg',
        '/images/oslavy/image06.jpg',
        '/images/oslavy/image07.jpg',
        '/images/oslavy/image08.jpg',
        '/images/oslavy/image09.jpg',
        '/images/oslavy/image10.jpg',
        '/images/oslavy/image11.jpg',
        '/images/oslavy/image12.jpg',
        '/images/oslavy/image13.jpg',
        '/images/oslavy/image14.jpg',
        '/images/oslavy/image15.jpg',
        '/images/oslavy/image16.jpg',
    ],
    tabor: [
        '/images/tabor/image11.jpg',
        '/images/tabor/image12.jpg',
        '/images/tabor/image13.jpg',
        '/images/tabor/image14.jpg',
        '/images/tabor/image01.jpg',
        '/images/tabor/image02.jpg',
        '/images/tabor/image03.jpg',
        '/images/tabor/image04.jpg',
        '/images/tabor/image05.jpg',
        '/images/tabor/image06.jpg',
        '/images/tabor/image07.jpg',
        '/images/tabor/image08.jpg',
        '/images/tabor/image09.jpg',
        '/images/tabor/image10.jpg',
    ],
    individual: [
        '/images/individual/image01.jpg',
        '/images/individual/image02.jpg',
        '/images/individual/image03.jpg',
        '/images/individual/image04.jpg',
    ],
}

const zigzagItems = [{
    image: images.kurz[0],
    icon: featIcon1,
    images: images.kurz,
    title: 'Dospělí: Kurzy v Praze',
    description: (
        <>
            <p>
                Kurzy jsou pro dospělé (účastníci 15-17 let se souhlasem rodičů), kteří hledají pravidelnou sportovní aktivitu.
                Šerm se světelným mečem je založen na funkčním bojovém umění a adaptovaný tak, 
                aby se co nejvíce blížil filmové předloze. Na své si ve strukturovaných lekcích přijdou jak 
                soutěživí zájemci, tak ti, kteří si chtějí aktivně odpočinout při sparringu či choreografii. 
                Součástí je i filozofie rytíře, kde se věnujeme tématům z psychologie, která lze využít v 
                každodenním životě. Zájemci o kurz potřebují pouze pohodlné sportovní oblečení, vše 
                ostatní je k zapůjčení. Kurz pro začátečníky se koná každou středu 19 - 21 v tělocvičně ZŠ u stanice metra C Vyšehrad.
            </p>
            <p className="price">
                <b>Cena za semestr</b> (20 x 2 hod): <span>5&nbsp;699&nbsp;Kč</span>
            </p>
        </>
    ),
},
{
    image: images.kurzDeti[0],
    icon: featIcon5,
    images: images.kurzDeti,
    title: 'Děti 8 - 14 let: Kurzy v Praze',
    description: (
        <>
            <p>
                Na tomto kurzu si děti osvojí základy šermu světelným mečem. Trénink se zaměří na cvičení koordinace, 
                rozvíjení pohybových dovedností a psychické rovnováhy. Šerm je postaven jak na filmových choreografiích, 
                tak na základech reálných bojových umění a může tak sloužit jako příprava pro další studium. Světelné meče 
                jsou všem účastníkům k zapůjčení. Zájemci o kurz potřebují pohodlné sportovní oblečení. Kurz 
                se koná každou středu v tělocvičně ZŠ u stanice metra C Vyšehrad.
            <p>
                Středa děti 8 - 12 let:     17 - 18 hod
                </p>
            <p>
                Středa děti 12 - 14 let:    18 - 19 hod

                </p>
            </p>
            <p className="price">
                <b>Cena za semestr</b> (20 x 1 hod): <span>2&nbsp;999&nbsp;Kč</span>
            </p>
        </>
    ),
},
{
    image: images.party[0],
    icon: featIcon2,
    images: images.party,
    title: 'Oslavy a zážitkové akce v Praze a okolí',
    description: (
        <>
            <p>
                Pro všechny věkové kategorie od 6 let. Program je uzpůsoben na míru věkové skupině a zahrnuje různé 
                tréninkové metody, ukázky, hry či šermířský turnaj tak, abyste si odnesli jedinečný zážitek dle svých představ. 
                Základní ochranné vybavení a světelné meče jsou k zapůjčení.
                Doporučená délka programu je od 90 minut. Místo konání je na dohodě. Maximální počet účastníků je 15.
            </p>
            <p><b>Ceník:</b></p>
            <CoursePriceTable rows={[
                ['2-3 účastníci', '90 min', '500 Kč / osoba'],
                ['4-9 účastníků', '90 min', '400 Kč / osoba'],
                ['10-15 účastníků', '90 min', '350 Kč / osoba'],
            ]} />
            <p><b>Pronájem prostor:</b></p>
            <CoursePriceTable rows={[
                ['Tělocvična', '90 min', 'od 800 Kč'],
                ['Veřejné sportoviště', '90 min', 'zdarma'],
            ]} />
            <p>+ možnost sjednání profesionálního nafocení akce</p>
        </>
    ),
// }, {
//     image: images.leto[1],
//     icon: featIcon3,
//     images: images.leto,
//     title: <>Letní kurz pro děti 2021<br />(červenec - srpen)</>,
//     description: (
//         <>
//             <p>
//                 <b>Kurz v českém jazyce:</b> dopolední nebo odpolední program
//                 Letní kurz šermu pro děti do 18 let v malých skupinkách do 4 účastníků. 
//                 Program je uzpůsoben požadavkům klienta, může se jednat o sportovní 
//                 den či o týdenní kurz. Místo konání je na dohodě.
//             </p>
//             <p className="price">
//                 <b>Půlden</b> (3 hodiny) <span>1&nbsp;799&nbsp;Kč</span>
//             </p>
//             <p>
//                 <b>Kurz v anglickém jazyce:</b> dopolední jazyková příprava, odpolední sportovní program 
//                 (nutná znalost základů AJ). Kurz je veden certifikovaným lektorem angličtiny a 
//                 jeho přidaná hodnota spočívá v okamžitém užití cizího jazyka v reálných situacích. 
//                 Studenti si tak po pár hodinách ani neuvědomí, že spolu komunikují cizím jazykem.
//             </p>
//             <p className="price">
//                 <b>Den (6 hodin)</b> <span>3&nbsp;599&nbsp;Kč</span>
//             </p>
//         </>
//     ),
}, {
    image: images.tabor[0],
    icon: featIcon4,
    images: images.tabor,
    title: <>Příměstský tábor pro děti<br />21. - 25. července 2025</>,
    description: (
        <>
            <p>
            Příměstský tábor pro děti ve věku 7 - 13 let.
            Bližší info bude k dispozici od listopadu 2024 
            <p className="price">
                <span><b>Cena:</b></span> <span>4&nbsp;900&nbsp;Kč</span>
            </p>

            </p>
        </>
    ),
}, {
    image: images.individual[0],
    icon: featIcon6,
    images: images.individual,
    title: <>Individuální lekce</>,
    description: (
        <>
            <p>
                Program lekce je plně přizpůsoben klientovi. Světelný meč a ochranné vybavení jsou k zapůjčení. 
                Místo a čas konání jsou na vzájemné domluvě. Pro venkovní tréninky jsou vhodné lokality například 
                Vyšehrad nebo Havlíčkovy sady.
            </p>
            <p className="price">
                <b>Cena (60 min):</b> <span>800&nbsp;Kč</span>
            </p>
            <p><b>Pronájem prostor:</b></p>
            <CoursePriceTable rows={[
                ['Tělocvična', '60 min', 'od 400 Kč'],
                ['Veřejné sportoviště', '60 min', 'zdarma'],
            ]} />
        </>
    ),
}]

const Home = () => {
    return (
        <Wrapper>
            <Hero />
            <FeaturesSection features={features} leftBadge={featIcon2} rightBadge={featIcon3} />
            <PromoSection />
            <ZigZagSection items={zigzagItems} />
            <VideoSection />
            <TwoColumnSection>
                <ContactForm />
                <SabersPromo />
            </TwoColumnSection>
            <Footer />
        </Wrapper>
    )
}

export default Home
