import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import fbIcon from './fb.png'
import igIcon from './Instagram.png'
import phoneIcon from './phone.png'
import mailIcon from './mail.png'
import comgateIcon from './comgate.png'

const StyledFooter = styled.div`
  width: 100%;
  text-align: center;
  padding: 3;
  color: white;
  background-color: backgroundDarker;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  a {
    color: textPrimary;
  }
  ${breakpoints({
        lg: css`
            flex-direction: row;
        `
    })}
`

const Phone = styled.h3`
    font-size: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        height: 60px;
    }
    a {
        display: block;
    }
    ${breakpoints({
        lg: css`
            font-size: 4;
            text-align: left;
            a {
                display: inline-block;
            }
        `
    })}
`

const Email = styled.h3`
    font-size: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        height: 60px;
    }
    a {
        display: block;
    }
    ${breakpoints({
    lg: css`
            font-size: 4;
            text-align: left;
            a {
                display: inline-block;
            }
        `
})}
`

const SocialNetwork = styled.h3`
    font-size: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        padding-right: ${props => props.imgP ? '0.5em' : undefined};
        height: 60px;
    }
    a {
        display: block;
    }
    ${breakpoints({
    lg: css`
            font-size: 4;
            text-align: left;
            a {
                display: inline-block;
            }
        `
})}
`

const Footer = () => {
  return (
    <>
        <StyledFooter id="contact">
            <Phone><img src={phoneIcon} alt="phone" /> <a href="tel:+420723452070">723&nbsp;452&nbsp;070</a></Phone>
            <Email><img src={mailIcon} alt="mail" /> <a href="mailto:info@saberacademy.cz">info@saberacademy.cz</a></Email>
            <SocialNetwork><img src={fbIcon} alt="fb" /> <a href="https://www.facebook.com/SaberAcademyPraha/" target="_blank" rel="noopener noreferrer">Saber Academy</a></SocialNetwork>
            <SocialNetwork imgP><img src={igIcon} alt="Instagram" /> <a href="https://www.instagram.com/saberacademypraha/" target="_blank" rel="noopener noreferrer">Saber Academy</a></SocialNetwork>
        </StyledFooter>
        <StyledFooter><img src={comgateIcon} height="40" alt="comgate" /></StyledFooter>
    </>
  )
}

export default Footer
