import React, { useEffect } from 'react'
import styled from '@xstyled/styled-components'
import FeaturesSection from '../../components/FeaturesSection'
import Hero from '../../components/Hero'
import Footer from '../../components/Footer'
import featIcon2 from './icon2.png'
import featIcon3 from './icon3.png'
import Onlineclass1 from '../../components/Onlineclass1'
import ZigZagSectionOnline from '../../components/ZigZagSectionOnline'

const Wrapper = styled.div`
    background-color: backgroundPrimary;
    color: textPrimary;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const features = [{
    title: 'Online lekce',
}]

const images = {
    kurzonline: [
        '/images/online/Screen 0.jpg',
        '/images/online/Screen 1.jpg',
        '/images/online/Screen 2.jpg',
        '/images/online/Screen 3.jpg',
        '/images/online/Screen 4.jpg',
    ],
}

const Button = styled.button`
    display: inline-block;
    background-color: buttonPrimaryBackground;
    font-size: 3;
    padding: 2;
    margin: 1;
    border: 1px solid;
    border-color: formInputBorder;
    text-decoration: none;
    color: white;
    border-radius: 3;
`

const CenteredListItem = styled.li`
    display: flex;
    align-items: center;
`;

const CenteredUl = styled.ul`
    list-style-type: none;
    display: flex;
    flex-direction: column;
    color: grayLight;
    align-items: center;
    padding: 0; /* Remove default padding on ul */
`;

const StrikethroughText = styled.span`
    text-decoration: line-through;
`;

const zigzagItems = [{
    image: images.kurzonline[0],
    images: images.kurzonline,
    description: (
        <>
        <div>
                 <h4><b>Obsahuje 10 instruktážních videí a dvě bonusová:</b></h4> 
            <CenteredUl>  
            <ul>
                <CenteredListItem>Úvod: Jaký meč je pro Vás ten pravý</CenteredListItem>
                <CenteredListItem>Lekce 1: Základní osmička</CenteredListItem>
                <CenteredListItem>Lekce 2: Basic Flow</CenteredListItem>
                <CenteredListItem>Lekce 3: Basic Flow s otočkou</CenteredListItem>
                <CenteredListItem>Lekce 4: Windmill</CenteredListItem>
                <CenteredListItem>Lekce 5: Infinity Loop</CenteredListItem>
                <CenteredListItem>Lekce 6: Skill Combo 1</CenteredListItem>
                <CenteredListItem>Lekce 7: Back Pass</CenteredListItem>
                <CenteredListItem>Lekce 8: Double Transfer</CenteredListItem>
                <CenteredListItem>Lekce 9: Skill Combo 2</CenteredListItem>
                <CenteredListItem>Lekce 10: Skill Combo 3</CenteredListItem>
                <CenteredListItem>Bonus 1: Levá ruka</CenteredListItem>
                <CenteredListItem>Bonus 2: Infinity Loop Level Generál</CenteredListItem>
            </ul>
            </CenteredUl>
                <b>Celková délka kurzu je 1 hodina 55 minut.</b>
                    
                <p> Po zakoupení získáte neomezený přístup k videím. </p>
                
                <Button as="a" href="https://form.simpleshop.cz/Dw9q/buy//">Koupit kurz</Button>
        </div>
            <p className="price">
            <span><b>Cena:</b></span> <StrikethroughText>(1&nbsp;899&nbsp;Kč)</StrikethroughText> <span>1&nbsp;499&nbsp;Kč</span>
            <p> Saber Academy, z.s., IČO: 19217471 </p>
            </p>
        </>
    ),
},
]

const Onlinelekce = () => {

    useEffect(() => {
        // Fetch data or perform initialization when the component is mounted
        const fetchData = async () => {
          try {
            const response = await fetch('https://www.saberacademy.cz/Onlinelekce');
            await response.json();
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData(); // Call the fetchData function
    
        // Clean-up function (optional): It runs before the component unmounts
        return () => {
          // Perform any clean-up, such as cancelling network requests
        };
      }, []);
    return (
        <Wrapper>
            <Hero />
            <FeaturesSection features={features} leftBadge={featIcon2} rightBadge={featIcon3} />
            <Onlineclass1 />
            <ZigZagSectionOnline items={zigzagItems} />
            <Footer />
        </Wrapper>
    )
}

export default Onlinelekce